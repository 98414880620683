import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Box,
  Grid,
} from "@mui/material";
import { Controller, useForm } from 'react-hook-form'
import LoginDashboard from "../../Navbar/MobileSidebar";
import { MdErrorOutline } from "react-icons/md";
import Sidbar from "../DesktopSidebar";
import MyProgramNav from "./MyProgramNav";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Select from 'react-select'
import Swal from "sweetalert2";
import { fetchVerticalProgramService, fetchCategoriesService, sendUpgradeRequest, fetchProgramTermsService } from "../../../services/programs.service";
import { useQuery } from "@tanstack/react-query";

const UpgradeProgram = () => {
  const program_id = localStorage.getItem("program_id")
  const category_id = localStorage.getItem("category")
  const [currentCategory, setCurrentCategory] = useState('');
  const [verticalOptions, setVerticalOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vertical: "",
      program: ""
    },
    mode: 'onChange',
  })

  const vertical_watch = watch('vertical')

  const { data: verticalData, isFetching: isProgramCategoriesFetching } = useQuery({
    queryKey: ['fetch-categories', 'active'], // Include status in the key to avoid cache conflicts
    queryFn: () => fetchCategoriesService("active"), // Pass as a function
  });


  const { data: term, refetch: refetchTerm } = useQuery({
    queryFn: () => fetchProgramTermsService({ id: program_id }),
    queryKey: ['fetch-program-term'],
    enabled: true
  })

  useEffect(() => {
    // Check if verticalData is available and term exists
    if (verticalData && term) {

      // Map through verticalData to create options based on conditions
      const options = verticalData
        .map((category) => {
          // Ensure category has a value before processing
          if (category.value) {

            // Check if the category includes the selected category_id
            if (category.category.includes(Number(category_id))) {

              // If max_term is greater than term.no_of_terms, include it in the options
              if (category.max_term > term?.no_of_terms) {
                return {
                  label: category.label,
                  value: category.label,
                };
              }

            } else {
              // If the category does not match the selected category_id, include it anyway
              return {
                label: category.label,
                value: category.label,
              };
            }
          }
          return undefined; // Return undefined for entries that don't meet the conditions
        })
        .filter((option) => option !== undefined); // Remove undefined entries

      // Update the state with the filtered options
      setVerticalOptions(options);
    }

    // Find the first category that matches the selected category_id
    const result = verticalData?.filter(item => item.category.includes(Number(category_id)))[0];

    // Update the current category label in the state
    setCurrentCategory(result?.label);

  }, [verticalData]); // Run effect whenever verticalData changes


  const { data: programsData, refetch: refetchPrograms } = useQuery({
    queryFn: () => fetchVerticalProgramService({ vertical: vertical_watch }),
    queryKey: ['fetch-vertical-program', vertical_watch],
    enabled: !!vertical_watch,
  })

  useEffect(() => {
    // Check if programsData is available and term exists before processing
    if (programsData && term) {

      // Map through programsData to create a list of program options
      const options = programsData.data
        .map((program) => {
          // Ensure program has a valid pid and it's not the same as the current program_id
          if (program.pid && program.pid !== program_id) {

            // If the current category matches vertical_watch, apply additional filtering
            if (currentCategory === vertical_watch) {

              // Include the program only if it has more terms than the selected term
              if (program?.no_of_terms > term?.no_of_terms) {
                return {
                  label: program.program_name,
                  value: program.program_name,
                };
              }

            } else {
              // If category doesn't match vertical_watch, include all programs except the current one
              return {
                label: program.program_name,
                value: program.program_name,
              };
            }
          }
          return undefined; // Return undefined for entries that don't meet the conditions
        })
        .filter((option) => option !== undefined); // Remove undefined entries

      // Update the state with the filtered program options
      setProgramOptions(options);
    }
  }, [programsData]); // Run effect whenever programsData changes


  const handleVerticalChange = (e) => {
    setValue("program", "")
  };

  const onSubmit = async (data) => {
    const sendDataresult = {
      // Get the user's information from local storage
      user_id: localStorage.getItem("user_id"),
      vertical: data.vertical,
      program: data.program,
      email: localStorage.getItem("email"),
      firstname: localStorage.getItem("username"),
      lastname: localStorage.getItem("lastname"),
      mobile: localStorage.getItem("mobile"),
      batch: localStorage.getItem("batch"),
      current_program: localStorage.getItem("program_name"),
    };

    try {
      // Send the request to upgrade the program
      const response = await sendUpgradeRequest(sendDataresult);
      if (response) {
        // Show the response message and feedback
        Swal.fire({
          title: response.message,
          text: response.feedback,
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#1A73E8"
        });
      }
    } catch (error) {
      // Handle any errors that may occur
      console.error(error);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      // Reset the form
      reset();
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '2px solid rgb(212 212 212)',
      borderRadius: '8px',
      padding: '4px 0',
      outline: 'none',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '16px',
      fontFamily: 'circular !important',
      textTransform: 'capitalize',
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      marginTop: '8px',
      backgroundColor: state.isDisabled ? '#E2E8F0' : provided.backgroundColor,
      curser: state.isDisabled ? 'not-allowed' : 'auto',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#a0a0a0' : provided.color,
      fontFamily: 'circular !important',
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: 'capitalize',
    })
  }
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: { lg: 8, xs: 3 }, px: { xs: 2, lg: 0 } }}>
          <Grid container>
            <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box sx={{ ml: 3 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "0.2rem!important",
                  }}
                >
                  Pause My Programe
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#a1a5b7" }}>
                  Home
                  <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#a1a5b7", mx: 0.5 }}
                  />
                  Pause My Programe
                </Typography>
              </Box>
              <Box>
                <MyProgramNav />
              </Box>
              <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
                <Box>
                  <Paper
                    sx={{
                      ml: { lg: 3 },
                      mr: { lg: 10 },
                      borderRadius: "8px",
                      boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                      p: { lg: 2, xs: 0 },
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ flex: 0.25 }}>

                    </Box>
                    {/* Left Side - Image */}
                    <Box sx={{ flex: 0.5, display: { xs: "none", md: "block" } }}>
                      <img
                        src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/program-upgrade.svg"
                        alt="Upgrade Request"

                        style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "8px" }}
                      />
                    </Box>

                    <Box sx={{ flex: 0.25 }}>
                    </Box>

                    {/* Right Side - Form */}
                    <Box sx={{ flex: 0.75, p: 3, width: "100%" }}>
                      <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
                        Upgrade Program
                      </h2>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="relative flex flex-col items-center justify-center gap-5">
                          {/* Category */}
                          <div className="w-full">
                            <Controller
                              name="vertical"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: {
                                  value: true,
                                  message: "Please select program vertical",
                                },
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  placeholder="Choose vertical"
                                  styles={customStyles}
                                  options={verticalOptions}
                                  value={
                                    verticalOptions.find((option) => option.value == field.value) || null
                                  }
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption?.value.toString());
                                    handleVerticalChange();
                                  }}
                                />
                              )}
                            />
                            {errors.vertical?.message && (
                              <div className="mt-2 flex w-full items-center gap-1 text-xs font-semibold text-red-500">
                                <MdErrorOutline size={14} />
                                <p>{errors.vertical?.message}</p>
                              </div>
                            )}
                          </div>

                          {/* Program */}
                          <div className="w-full">
                            <Controller
                              name="program"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: {
                                  value: true,
                                  message: "Please select Program",
                                },
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  placeholder="Choose Program"
                                  styles={customStyles}
                                  options={programOptions}
                                  value={
                                    programOptions.find((option) => option.value === field.value) ||
                                    null
                                  }
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption?.value.toString());
                                  }}
                                />
                              )}
                            />
                            {errors.program?.message && (
                              <div className="mt-2 flex w-full items-center gap-1 text-xs font-semibold text-red-500">
                                <MdErrorOutline size={14} />
                                <p>{errors.program?.message}</p>
                              </div>
                            )}
                          </div>

                          {/* Submit Button */}
                          <button
                            onClick={handleSubmit}
                            disabled={!vertical_watch}
                            className={`w-full px-4 py-2 text-white rounded-lg ${vertical_watch
                              ? "bg-blue-500 hover:bg-blue-600 cursor-pointer"
                              : "bg-gray-400 cursor-not-allowed"
                              }`}
                          >
                            Request for Upgrade
                          </button>
                        </div>
                      </form>
                    </Box>
                    <Box sx={{ flex: 0.2 }}>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default UpgradeProgram;
