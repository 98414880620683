import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Link,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import LoginDashboard from "../Navbar/MobileSidebar";
import Sidbar from "./DesktopSidebar";
import axios from "axios";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from 'sweetalert2';
import AuthSidebar from "../../Auth/AuthSidebar";
const JobProtal = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState("");
  const [openloder, setOpenloder] = useState("");
  const [user, setUser] = useState("");
  const [Job, setJob] = useState("");
  const [Jobid, setJobid] = useState("");
  const [Joblink, setJoblink] = useState("");
  const [Jobcategory, setJobcategory] = useState("");
  const [reporttype, setReporttype] = useState("");
  const [reprotdiscrtiption, setreprotdiscrtiption] = useState("");
  const handleChangesetJobid = (event) => {
    setJobid(event.target.value);
  };
  const handleChangesetJoblink = (event) => {
    setJoblink(event.target.value);
  };
  const handleChangesetJobcategory = (event) => {
    Jobcategory(event.target.value);
  };
  const handleChangediscrtiption = (event) => {
    setreprotdiscrtiption(event.target.value);
  };
  const handleChange = (event) => {
    setReporttype(event.target.value);
  };
  const handleClickOpen = (id, job_link, category) => {
    setJobid(id);
    setJoblink(job_link);
    setJobcategory(category);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const LoaderClose = () => {
    setOpenloder(false);
  };
  const LoaderOpen = () => {
    setOpenloder(true);
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    category: localStorage.getItem("category"),
  };
  const CourseData = {
    enrol_id: localStorage.getItem("enrol_id"),
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchjobprotal`, JSON.stringify(sendData), options
      )
      .then((result) => {
        if (result.data.status == 300) {
          setJob([]);
        }
        else {
          setJob(result.data);
        }
      });
  }, []);
  const submitForm = async (e) => {
    LoaderOpen();
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      enrol_id: localStorage.getItem("enrol_id"),
      report_type: reporttype,
      job_id: Jobid,
      job_category: parseInt(localStorage.getItem("category")),
      report_des: reprotdiscrtiption,
      job_link: Joblink,

    };
    await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/jobreportmail/reportjob`, JSON.stringify(sandData), options
      )
      .then((result) => {
        if (result.data.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you",
            icon: "success",

            confirmButtonText: "OK",
          });

        }
        setReporttype("");
        setJobid("");
        setJoblink("");
        setJobcategory("");
        setreprotdiscrtiption("");
        LoaderClose();
        handleClose();

      });
    //console.log(sandData);
  };

  // if (user == null) {
  //   window.location.href="https://accredian.com"
  // }

  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: 8 }}>
          <Grid container>
            <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
              {/* <AuthSidebar/> */}
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.5rem",
                    marginBottom: "0rem !important",
                    mb: 3,
                  }}
                >
                  Job Listing
                </Typography>
              </Box>
              <Box>
                <Paper
                  sx={{
                    mr: 5,
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                  }}
                >
                  <Grid container>
                    <Grid item lg={2.5}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "0.8rem",
                          marginBottom: "0rem !important",
                          textAlign: "center",
                        }}
                      >
                        Designation
                      </Typography>
                    </Grid>
                    <Grid item lg={3}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "0.8rem",
                          marginBottom: "0rem !important",
                          textAlign: "center",
                        }}
                      >
                        Company
                      </Typography>
                    </Grid>
                    <Grid item lg={2.5}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "0.8rem",
                          marginBottom: "0rem !important",
                          textAlign: "center",
                        }}
                      >
                        Seniority Level
                      </Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "0.8rem",
                          marginBottom: "0rem !important",
                          textAlign: "center",
                          mr: 3,
                        }}
                      >
                        Date Posted
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box sx={{ mt: 3, mb: 4 }}>
                    {Job &&
                      Job.map(
                        ({
                          id,
                          job_title,
                          company_name,
                          level,
                          created,
                          job_link,
                          category,
                        }) => (
                          <Box
                            sx={{
                              boxShadow:
                                "rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px",
                              mx: 2,
                              p: 2,
                              borderRadius: "5px",

                              height: "65px",
                              py: 3,
                            }}
                            key={id}
                          >
                            <Grid container>
                              <Grid item lg={2.5}>
                                <Link
                                  href={job_link}
                                  target="_blank"
                                  sx={{ textDecoration: "none", color: "#000" }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                    }}
                                  >
                                    {job_title}
                                  </Typography>
                                </Link>
                              </Grid>
                              <Grid item lg={3}>
                                <Link
                                  href={job_link}
                                  target="_blank"
                                  sx={{ textDecoration: "none", color: "#000" }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "0.9rem",
                                      marginBottom: "0rem !important",
                                      color: "#055FA0",
                                      textAlign: "center",
                                    }}
                                  >
                                    {company_name}
                                  </Typography>
                                </Link>
                              </Grid>
                              <Grid item lg={2.5}>
                                <Link
                                  href={job_link}
                                  target="_blank"
                                  sx={{ textDecoration: "none", color: "#000" }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                      textAlign: "center",
                                    }}
                                  >
                                    {level} level
                                  </Typography>
                                </Link>
                              </Grid>
                              <Grid item lg={2}>
                                <Link
                                  href={job_link}
                                  target="_blank"
                                  sx={{ textDecoration: "none", color: "#000" }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                      textAlign: "center",
                                    }}
                                  >
                                    {created}
                                  </Typography>
                                </Link>
                              </Grid>

                              <Grid item lg={2}>
                                <Box
                                  display="flex"
                                  onClick={() =>
                                    handleClickOpen(id, job_link, category)

                                  }
                                >
                                  <ReportProblemIcon
                                    sx={{ fontSize: "1.2rem", color: "red", cursor: 'pointer' }}
                                  />
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "0.9rem",
                                      marginBottom: "0rem !important",
                                      textAlign: "center",
                                      color: "red",
                                      cursor: 'pointer',
                                      ml: 1,
                                    }}
                                  >
                                    Report Job
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        )
                      )}
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Report job posting"}{" "}
          <ClearIcon onClick={handleClose} sx={{ float: "right", cursor: 'pointer' }} />
        </DialogTitle>
        <form onSubmit={submitForm}>
          <DialogContent>

            <TextField
              id="Jobid"
              label="Jobid"
              variant="outlined"
              name="Jobid"
              value={Jobid}
              onChange={handleChangesetJobid}
              sx={{ visibility: "hidden" }}
            />
            <TextField
              id="Joblink"
              label="Joblink"
              variant="outlined"
              name="Joblink"
              value={Joblink}
              onChange={handleChangesetJoblink}
              sx={{ visibility: "hidden" }}
            />
            <TextField
              id="Jobcategory"
              label="Jobcategory"
              variant="outlined"
              name="Jobcategory"
              value={Jobcategory}
              onChange={handleChangesetJobcategory}
              sx={{ visibility: "hidden" }}
            />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="reporttype"
              onChange={handleChange}
              value={reporttype}
              sx={{ mt: -13 }}
            >
              <FormControlLabel
                value="Wrong posting"
                control={<Radio />}
                label="Wrong posting"
                name="reporttype"
              />
              <FormControlLabel
                value="Expired job"
                control={<Radio />}
                label="Expired job"
                name="reporttype"
              />
              <FormControlLabel
                value="Not relevant"
                control={<Radio />}
                label="Not relevant"
                name="reporttype"
              />
              <FormControlLabel
                value="Fake job"
                control={<Radio />}
                label="Fake job"
                name="reporttype"
              />
            </RadioGroup>
            <TextField
              id="outlined-multiline-static"
              label="Explain this report"
              multiline
              rows={3}
              placeholder="Explain this report"
              name="reprotdiscrtiption"
              value={reprotdiscrtiption}
              onChange={handleChangediscrtiption}
              fullWidth
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions sx={{ mb: 4, justifyContent: "center" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                mb: 0.3,

                mr: 3,
                background: "#009ef7",

                borderRadius: "8px",
                textTransform: "none",

                "&:hover": {
                  background: "#009ef7",

                  borderRadius: "8px",
                  textTransform: "none",
                },
              }}
            >
              Submit
            </Button>

            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                mb: 0.3,
                mr: 3,
                background: "#f5f8fa",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",

                "&:hover": {
                  background: "#f5f8fa",
                  borderRadius: "8px",
                  textTransform: "none",
                  color: "#000",
                },
              }}
            >
              Discard
            </Button>

          </DialogActions>
        </form>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openloder}

        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

    </>
  );
};
export default JobProtal;
