import './App.css';
import React, { useState } from "react";
import { useEffect, Suspense, lazy } from 'react';
// import Login from './Componentshttps://frontend-project2.accredian.com/loginhttps://frontend-project2.accredian.com/login';
// import AuthLogin from './Auth/AuthLogin';
// import AuthSignup from './Auth/AuthSignup';
import ChangePassword from './Components/Dashboard/ChangePassword';
import AuthDashbord from './Auth/AuthDashbord';
import AuthMyaccount from './Auth/AuthMyaccount';
import AuthCareercounselling from './Auth/AuthCareercounselling';
import AuthSuccesstory from './Auth/AuthSuccesstory';
import AuthCalendar from './Auth/AuthCalendar';
import AuthProfile from './Auth/AuthProfile';
import AuthDuefee from './Auth/AuthDuefee';
import AuthReceipt from './Auth/AuthReceipt';
import AuthBatchTransfer from './Auth/AuthBatchTransfer';
import AuthPauseprogram from './Auth/AuthPauseprogram';
import AuthUpgradeprogram from './Auth/AuthUpgradeprogram';
import AuthCertificationexam from './Auth/AuthCertificationexam';
import AuthJobprotal from './Auth/AuthJobprotal';
import AuthCareerlaunchpad from './Auth/AuthCareerlaunchpad';
import AuthArticle from './Auth/AuthArticle';
import AuthCaseStudies from './Auth/AuthCaseStudies';
import AuthCheatSheet from './Auth/AuthCheatSheet';
import AuthGuides from './Auth/AuthGuides';
import AuthInfographics from './Auth/AuthInfographics';
import AuthInterviewQuestions from './Auth/AuthInterviewQuestions';
import AuthResearchPaper from './Auth/AuthResearchPaper';
import AuthEBooks from './Auth/AuthEBooks';
import AuthBooks from './Auth/AuthBooks';
import AuthBookpanel from './Auth/AuthBookpanel';
import AuthCertificates from './Auth/AuthCertificates';
import AuthProductResearch from './Auth/AuthProductResearch';
import AuthProblemStatement from './Auth/AuthProblemStatement';
import AuthProductstrategy from './Auth/AuthProductstrategy';
import AuthUiux from './Auth/AuthUiux';
import AuthProductDevelopment from './Auth/AuthProductDevelopment';
import AuthGoToMarket from './Auth/AuthGoToMarket';
import AuthProjects from './Auth/AuthProjects';
import AuthTermProject from './Auth/AuthTermProject';
import AuthPmArticle from './Auth/AuthPmArticle';
import AuthPmCaseStudies from './Auth/AuthPmCaseStudies';
import AuthPmCheatSheet from './Auth/AuthPmCheatSheet';
import AuthPmInterviewQuestions from './Auth/AuthPmInterviewQuestions';
import AuthPmResearchPaper from './Auth/AuthPmResearchPaper';
import AuthCompetitioncorner from './Auth/AuthCompetitioncorner';
import AuthResumetoolkit from './Auth/AuthResumetoolkit';
import AuthQuiz from './Auth/AuthQuiz';
import AuthJobquiz from './Auth/AuthJobquiz';
import AuthCareerResources from './Auth/AuthCareerResources';
import AuthJobguarantee from './Auth/AuthJobguarantee';
import Certificate from './Components/certificate/certificate';
import Competitioncornerquestion from './Components/Dashboard/Competition-corner/competition-corner-question';
// import Signup from './Components/Signup/Signup';
// import Lostpassword from './Components/Home-password/Lostpassword';
// import Resetpassword from './Components/Home-password/Resetpassword';
// import CareerTrasition from './Components/Dashboard/Career-Transition/career';
import CareerQuiz from './Components/Dashboard/Career-Transition/quiz/quiz'
import FEE from './Components/Dashboard/fee-recepit/fee';
import HowTo from './Components/Dashboard/Howto';
import AuthByprogram from './Auth/AuthByprogram';
import { useNavigate, Link } from "react-router-dom";
import Swal from 'sweetalert2'
import Myaccount from './Components/Myaccount/Myaccount';
import StudentDetails from './Components/Dashboard/studentDetails';
// import Logout from './Componentshttps://frontend-project2.accredian.com/login/Logout';
// import Keymodule from './Resource/key-modules';


// import SkeletonWL from './Components/Skeleton/Withoutlogin';
import SkeletonL from './Components/Skeleton/Withlogin';
import axios from 'axios'



// import Alumni from './Components/Dashboard/Alumni/alumni';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import ZoomComponent from './Components/Dashboard/zoom-sdk/zoomComponent';
// import AppZoom from './Components/Dashboard/zoom-sdk/zoomComponent2';


const CareerTrasition = lazy(() => import('./Components/Dashboard/Career-Transition/career'));
const Alumni = lazy(() => import('./Components/Dashboard/Alumni/alumni'));

function CareerTransitionPrivet() {
  const useAuth = () => {
    const user = localStorage.getItem('user_id')
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

    if (token) {
      return true
    } else {
      localStorage.clear()
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Suspense fallback={<SkeletonL />}>
    <CareerTrasition />
  </Suspense> : <>
    {
      Swal.fire({
        title: "Timeout",
        text: "Your Session has been expired please relogin",
        icon: "info",
        confirmButtonText: "Ok"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "https://accredian.com"
        }
      })
    }
  </>
}
function AlumniPrivet() {
  const useAuth = () => {
    const user = localStorage.getItem('user_id')
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

    if (token) {
      return true
    } else {
      localStorage.clear()
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Suspense fallback={<SkeletonL />}>
    <Alumni />
  </Suspense> : <>
    {
      Swal.fire({
        title: "Timeout",
        text: "Your Session has been expired please relogin",
        icon: "info",
        confirmButtonText: "Ok"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "https://accredian.com"
        }
      })
    }
  </>
}
function CareerQuizPrivet() {
  const useAuth = () => {
    const user = localStorage.getItem('user_id')
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

    if (token) {
      return true
    } else {
      localStorage.clear()
      return false
    }
  }
  const auth = useAuth();
  return auth ? <CareerQuiz /> : <>
    {
      Swal.fire({
        title: "Timeout",
        text: "Your Session has been expired please relogin",
        icon: "info",
        confirmButtonText: "Ok"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "https://accredian.com"
        }
      })
    }
  </>
}

function App() {


  return (
    <>
      <Routes>

        {/* {localStorage.getItem('user_id')} */}
        {/* <Route path='/WordpressPasswordGenerator' element={<WordpressPasswordGenerator/>} />
        <Route path='/testing2' element={<PDFDragDrop/>} /> */}


        {/* <Route path='/Logout' element={<Logout />} /> */}
        {/* <Route path='/Lost-password'  element={<Lostpassword />} /> */}
        {/* <Route path='/Reset-password/:id'  element={<Resetpassword />} /> */}
        <Route path='/' element={<Myaccount />} />
        <Route path='/studentDetails' element={<StudentDetails />} />
        <Route path='/Dashboard' element={<AuthDashbord />} />
        <Route path='/CareerCounselling' element={<AuthCareercounselling />} />
        <Route path='/SuccessStory' element={<AuthSuccesstory />} />
        <Route path='/Calendar' element={<AuthCalendar />} />
        <Route path='/Profile' element={<AuthProfile />} />
        <Route path='/ChangePassword' element={<ChangePassword />} />
        <Route path='/Duefee' element={<AuthDuefee />} />
        <Route path='/Receipt' element={<AuthReceipt />} />
        <Route path='/Batch-Transfer' element={<AuthBatchTransfer />} />
        <Route path='/Pause-Program' element={<AuthPauseprogram />} />
        <Route path='/Program-Upgrade' element={<AuthUpgradeprogram />} />
        <Route path='/Certification-Exam' element={<AuthCertificationexam />} />
        <Route path='/job-listing' element={<AuthJobprotal />} />
        <Route path='/Career-Launchpad' element={<AuthCareerlaunchpad />} />
        <Route path='/Article' element={<AuthArticle />} />
        <Route path='/Case-Studies' element={<AuthCaseStudies />} />
        <Route path='/Cheat-Sheet' element={<AuthCheatSheet />} />
        <Route path='/Guides' element={<AuthGuides />} />
        <Route path='/Infographics' element={<AuthInfographics />} />
        <Route path='/Interview-Questions' element={<AuthInterviewQuestions />} />
        <Route path='/Research-Paper' element={<AuthResearchPaper />} />
        <Route path='/E-Books' element={<AuthEBooks />} />
        <Route path='/Books' element={<AuthBooks />} />
        <Route path='/Book-Panel' element={<AuthBookpanel />} />
        <Route path='/PM-Article' element={<AuthPmArticle />} />
        <Route path='/PM-Case-Studies' element={<AuthPmCaseStudies />} />
        <Route path='/PM-Cheat-Sheet' element={<AuthPmCheatSheet />} />
        <Route path='/PM-Interview-Questions' element={<AuthPmInterviewQuestions />} />
        <Route path='/PM-Research-Paper' element={<AuthPmResearchPaper />} />
        <Route path='/Certificates' element={<AuthCertificates />} />
        <Route path='/Product-research' element={<AuthProductResearch />} />
        <Route path='/Product-strategy' element={<AuthProductstrategy />} />
        <Route path='/Ui-Ux' element={<AuthUiux />} />
        <Route path='/Product-development' element={<AuthProductDevelopment />} />
        <Route path='/Problem-statement' element={<AuthProblemStatement />} />
        <Route path='/Go-To-Market' element={<AuthGoToMarket />} />
        <Route path='/Projects' element={<AuthProjects />} />
        <Route path='/Term-projects/:id' element={<AuthTermProject />} />
        <Route path='/Term-projects-quiz' element={<AuthQuiz />} />
        <Route path='/Competition-corner' element={<AuthCompetitioncorner />} />
        <Route path='/Resume-toolkit' element={<AuthResumetoolkit />} />
        <Route path='/Job-guarantee' element={<AuthJobguarantee />} />
        <Route path='/Job-guarantee-quiz' element={<AuthJobquiz />} />
        <Route path='/certificate' element={<Certificate />} />
        <Route path='/competition-corner-question' element={<Competitioncornerquestion />} />
        <Route path='/career-transition' element={<CareerTransitionPrivet />} />
        <Route path='/career-transition-quiz' element={<CareerQuizPrivet />} />
        <Route path='fee' element={<FEE />} />
        <Route path='/By-Program' element={<AuthByprogram />} />
        <Route path='/career-resources' element={<AuthCareerResources />} />
        <Route path='/alumni' element={<AlumniPrivet />} />

      </Routes>
    </>
  );
}
export default App;











