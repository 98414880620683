import api from "./server";

/**
 * Fetches the list of categories from the server.
 */
export const fetchCategoriesService = async (status) => {
  try {
    // Construct query params if status exists
    const query = status ? `?status=${status}` : "";

    // Make an API request to fetch categories
    const { data } = await api.get(`/program/fetch-categories${query}`);

    // Return the data received from the server
    return data;
  } catch (error) {
    // Throw an error if the request fails
    throw error;
  }
};


export const fetchProgramTermsService = async (body) => {
  try {
    const { data } = await api.post(`/program/fetch-program-term`, body);

    return data;
  } catch (error) {
    throw error;
  }
}

/**
 * Fetch the programs for a given vertical.
 */
export const fetchVerticalProgramService = async (body) => {
  try {
    const { data } = await api.post(`/students/fetch-vertical-program`, body);

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * Send an email to the student and the program manager requesting a program
 * upgrade.

 */
export const sendUpgradeRequest = async (body) => {
  try {
    const { data } = await api.post(`/program/send-upgrade-email`, body);

    return data;
  } catch (error) {
    throw error;
  }
};
